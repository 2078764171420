import { Container, Box, Typography, Button, Collapse } from '@mui/material';
import ExpandMore from '../components/ExpandMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

const faqItems: { heading: string; body: string }[] = [
  {
    heading: 'Moderation Policy',
    body: 'Reviews and images may be removed due to excessive profanity or is generally offensive in nature. To report a review or image, please use the contact form. We do not remove reviews because they are unflattering or "things have changed since the review was posted".',
  },
];

const Faq = () => {
  const [open, setOpen] = useState<number | null>(0);

  const onClickHandler = (n: number) => () => {
    setOpen((prev) => {
      if (prev === n) return null;
      else return n;
    });
  };

  return (
    <Container sx={{ padding: { xs: '5px', sm: '10px' } }}>
      <Box>
        <Typography variant="h1">FAQ</Typography>
        <Container>
          <Container disableGutters sx={{ border: '1px solid #e2e2e2', borderRadius: '4px' }}>
            {faqItems.map(({ heading, body }, idx) => (
              <Box key={`faq-${idx}`}>
                <Button
                  sx={{ width: '100%', backgroundColor: '#e9e9e9' }}
                  onClick={onClickHandler(idx)}
                >
                  {heading}
                  <ExpandMore expand={idx === open}>
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Button>
                <Collapse
                  sx={{
                    borderTop: '1px solid #e2e2e2',
                    padding: idx === open ? '10px' : '0px',
                  }}
                  in={idx === open}
                >
                  <Typography>{body}</Typography>
                </Collapse>
              </Box>
            ))}
          </Container>
        </Container>
      </Box>
    </Container>
  );
};

export default Faq;
